import React from "react";
import { BsFacebook, BsTelegram } from "react-icons/bs";
import { FiGithub } from "react-icons/fi";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.facebook.com/israilzadehsukhrob/" target="_blank">
        <BsFacebook />
      </a>
      <a href="https://github.com/oxaDeveloper/" target="_blank">
        <FiGithub />
      </a>
      <a href="https://t.me/israelzadeh_s/" target="_blank">
        <BsTelegram />
      </a>
    </div>
  );
};

export default HeaderSocials;
