import React from "react";
import facebook from "../../assets/facebook.png";
import amazon from "../../assets/amazon.png";
import rinok from "../../assets/rinok.png";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              src={amazon}
              alt=""
            />
          </div>
          <h3>Amazon (Clone)</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/oxaDeveloper/"
              target="_blank"
              className="btn"
            >
              GitHub
            </a>
            <a
              href="#"
              target="_blank"
              className="btn btn-primary"
            >
              See WebSite
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              src={facebook}
              alt=""
            />
          </div>
          <h3>Facebook (Clone)</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/oxaDeveloper/"
              target="_blank"
              className="btn"
            >
              GitHub
            </a>
            <a
              href="https://facebook-oxa.vercel.app/"
              target="_blank"
              className="btn btn-primary"
            >
              See WebSite
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              src={rinok}
              alt=""
            />
          </div>
          <h3>Construction market</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/oxaDeveloper/"
              target="_blank"
              className="btn"
            >
              GitHub
            </a>
            <a href="https://str-rinok-oxa.vercel.app/" target="_blank" className="btn btn-primary">
              See WebSite
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
